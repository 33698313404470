<template>
    <div >
     <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
    
            <v-card>
                <v-toolbar flat color="white" class="mt-3">
                    <v-toolbar-title>TRANSFERENCIAS</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        >
                        </v-divider>
                        
                    <v-col cols="3" class="pt-4 mt-4">
                        <v-menu
                        v-model = "menu"
                        :close-on-content-click = "false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="rangoFechas"
                                    label="Fechas"
                                    persistent-hint
                                    readonly
                                    append-icon="mdi-calendar-multiple"
                                    v-bind="attrs"
                                    v-on="on"
                                    :clearable="true"
                                    @click:clear="limpiaFechas()"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                range
                                v-model="searchFechas"
                                no-title
                                @change="cargarLista()"
                                locale="es-es"
                            >
                            </v-date-picker>
                        </v-menu>
                </v-col >
                <v-col class="pt-4 mt-4" cols="2">
                    <v-select clearable 
                     v-model="estadoId" 
                     label="Estado" 
                     :items="estados" 
                     item-text="nombre" 
                     @change="cargarLista()"
                     item-value="estado_id"></v-select>
                </v-col>

                <v-col class="pt-4 mt-4" cols="2">
                    <v-text-field
                    clearable 
                    append-icon="mdi-magnify"
                    @keyup.enter="cargarLista()"
                    @click:append="cargarLista()"
                     v-model="monto" label="monto"></v-text-field>
                </v-col>

                <v-col class="pt-4 mt-4" cols="2">
                    <v-text-field 
                    clearable 
                    append-icon="mdi-magnify"
                    @keyup.enter="cargarLista()"
                    @click:append="cargarLista()" v-model="referencia" label="Referencia"></v-text-field>
                </v-col>
        </v-toolbar>

        <!-- <v-toolbar flat color="white">
            <v-btn  color="primary" small  class="ma-2 white--text" 
            @click="exportar()"
            >
                <v-icon left >mdi-microsoft-excel</v-icon>
            Exportar
            </v-btn>
        </v-toolbar> -->
    
        <v-data-table
                :headers="headersLista"
                :items="lista"

                hide-default-footer
                :loading=loadingTable                
                :items-per-page=itemsPerPage
                :options.sync="options"
                loading-text="Cargando datos..."
                no-results-text="No se han encontrado datos"
                no-data-text="Sin datos"
                class="elevation-1"
                dense
                @update:options="cargarLista()"
            >
            <template v-slot:item.opciones="{item}">
                <v-btn @click="mostrarEmail(item)" title="Ver email de la transferencia" small color="blue" icon><v-icon>mdi-email</v-icon></v-btn>
                
                <v-btn @click="mostrarDialogEstadoTrans(item)" title="Cambiar estado de la transferencia" color="orange" small icon><v-icon>mdi-swap-vertical-bold</v-icon></v-btn>
            </template>
            <template v-slot:footer>
                <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
                    total-visible="10"></v-pagination>
            </template>
        </v-data-table>
        
        </v-card>

        <v-dialog v-model="dialogEmail" width="600">
            <v-card>
                <v-toolbar height="40" color="blue" dark flat>
                    <v-icon left >mdi-email</v-icon>
                    <v-toolbar-title >Mail de la transferencia</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEmail=!dialogEmail"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>                                
                <div id="emailc" v-html='emailContent' ></div>                                
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEstadoTrans" width="600">
            <v-card>
                <v-toolbar height="40" dark color="blue" flat>
                    <v-icon left >mdi-swap-vertical-bold</v-icon>
                    <v-toolbar-title >Estado de la transferencia</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEstadoTrans=!dialogEstadoTrans"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select clearable 
                                v-model="datosTrans.estado_id" 
                                label="Estado" 
                                :items="estados" 
                                item-text="nombre" 
                                @change="cargarLista()"
                                item-value="estado_id"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 pt-0">
                        <v-col class="mt-0 pt-0">
                            <v-text-field readonly v-model="datosTrans.fecha_transferencia" label="Fecha"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 pt-0">
                        <v-col class="mt-0 pt-0">
                            <v-text-field readonly v-model="datosTrans.nombre_ordenante" label="Transferido por"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 pt-0">
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field readonly v-model="datosTrans.monto" label="Monto"></v-text-field>
                        </v-col>
                        <v-col cols="6" class="mt-0 pt-0">
                            <v-text-field readonly v-model="datosTrans.num_ref" label="Referencia"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 pt-0">
                        <v-col cols="12" class="mt-0 pt-0">
                            <v-text-field readonly v-model="datosTrans.concepto" label="Concepto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-toolbar>
                    <v-col>
                        <v-btn small color="green" dark @click="modificarEstado()"><v-icon>mdi-content-save</v-icon>Guardar</v-btn>
                    </v-col>
                </v-toolbar>
            </v-card>
        </v-dialog>
        
    </div>
    </template>
    
    <script>
    import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
    
    export default {
        name: "TransferenciasBanco",
        
        data: ()=> ({
            headersLista: [
                { text: 'Opciones', value: 'opciones'},
                { text: 'Fecha', value: 'fecha_transferencia' , sortable:true},
                { text: 'Transferido por', value: 'nombre_ordenante' , sortable:true},
                { text: 'Cuenta bancaria', value: 'num_cuenta' , sortable:true},
                { text: 'Monto.', value: 'monto' , sortable:true},
                { text: 'Referencia.', value: 'num_ref' , sortable:true},
                { text: 'Concepto.', value: 'concepto' , sortable:false},
                { text: 'Estado.', value: 'estado_id' },
                { text: 'Usuario', value: 'usuario_confirma' },
                { text: 'Cot.#', value: 'cotizacion_id' },
                { text: 'Banco origen', value: 'banco_origen' },
            ],
            

            listaEstab: [],
            lista: [],
            overlay: false,
            options: {},
            currentPage: 1,
            pageCount: 0,
            itemsPerPage: 25,
            dialog: false,
            notifications: false,
            sound: true,
            widgets: true,
            searchFechas: [],
            searchEstab: "",
            menu: false,
            estados2: [{ estado_id:'RECIBIDO', nombre: 'RECIBIDO'},{estado_id:'CONFIRMADO', nombre:'CONFIRMADO'}],
            estados: [{ estado_id:'RECIBIDO', nombre: 'RECIBIDO'},{estado_id:'CONFIRMADO', nombre:'CONFIRMADO'}, {estado_id:'CONFIRMADO CUENTA',nombre:'CONFIRMADO CUENTA'} ,{estado_id:'NO CONFIRMADO', nombre:'NO CONFIRMADO'}],
            monto: "",
            referencia:"",
            estadoId: "",
            filters:[
                
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                    v_model:[],
                    label: 'Fecha',
                    type:'input_date',
                    clearable:true,
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Bodega',
                    type:'input_select',
                    text:'tipoNombre',
                    value:'tipoId',
                    clearable:true,
                    multiple:true,
                    items:[]
                }],
                emailContent: "",
                dialogEmail: false,
                dialogEstadoTrans: false,
                datosTrans: {}
                   
        }),
        computed: {
            ...mapState('master',['loadingTable','user','tenantId']),            
            ...mapGetters('access', ['btnAbrirCaja']),
             
            rangoFechas () {
                return this.searchFechas.join(' ~ ')
             },
        },
        methods: {
            ...mapMutations('master',['setUrl','setMenu','setTitleToolbar','setLoadingTable']),               
            ...mapActions('master',['requestApi','alertNotification']),

            exportar() {
                this.overlay=true
                
                if(this.searchFechas[0] == null) {
                    this.overlay=false
                    //alert('Seleccione una fecha');
                    this.alertNotification({
                        param:{
                            html: 'Seleccione una fecha.',
                            timer: 10000,
                            title: 'Error',
                            icon: 'mdi-cancel',
                            confirmButtonColor: 'red'
                        }
                    });
                    
                    return false;
                }   
                                                
                this.setUrl('transferencia-banco')
                this.requestApi({
                    method : 'GET',
                    data : {                        
                        "desde": this.searchFechas[0],
                        "hasta" : this.searchFechas[1],                                                
                    }
                }).then(res=>{
                    //console.log(res.data);			        			    		                    
                    var a = document.createElement("a");
                    a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
                    a.download = res.data.archivo;
                    a.click();
                    this.overlay=false
                }).then(()=>{
                      
                });
            },
            limpiaFechas() {
                this.searchFechas = [];
                this.cargarLista();
            },
            cargarLista() {            
                //console.log(this.searchEstab.codigo_establecimiento)
                this.setUrl('transferencia-banco')
                this.requestApi({
                    method : 'GET',
                    data : {            
                        "options": this.options,            
                        "desde": this.searchFechas[0],
                        "hasta" : this.searchFechas[1],
                        "estado_id" : this.estadoId,
                        "referencia": this.referencia,
                        "monto" : this.monto,
                        "page" : this.currentPage,
                        "page_size" : this.itemsPerPage
                    }
                }).then(res=>{
                    console.log(res.data._embedded.transferencia_banco)
                    this.lista = res.data._embedded.transferencia_banco;
                    this.pageCount = res.data.page_count;
                    //this.currentPage = res.data.page;
                    this.menu = false;
                }).then(()=>{
                      
                });                
            },
    
            cargarEstablecimientos() {
                this.setUrl('establecimiento')
                this.requestApi({
                    method : 'GET',
                    data : {
                                    
                    }
                }).then(res=>{            				        			    		
                    this.listaEstab = res.data._embedded.establecimiento;
                }).then(()=>{
                      
                });
    
            },
            handlePageChange(value) {
                this.cargarLista();
            },

            mostrarEmail(item){
                this.emailContent=item.mensaje_correo
                this.dialogEmail=true;
            },
            mostrarDialogEstadoTrans(item){
                this.dialogEstadoTrans=true;
                this.setUrl('transferencia-banco')
                this.requestApi({
                    method : 'POST',
                    data : {
                        accion: "datosTransferencia",
                        transferencia_id: item.id  
                    }
                }).then(res=>{            				        			    		
                    //this.listaEstab = res.data._embedded.establecimiento;
                    console.log(res.data.detail[0])
                    this.datosTrans = res.data.detail[0]
                }).then(()=>{
                      
                });
            },
            modificarEstado() {
                this.dialogEstadoTrans=true;
                this.setUrl('transferencia-banco')
                this.requestApi({
                    method : 'POST',
                    data : {
                        accion: "modificarEstadoTransferencia",                        
                        id: this.datosTrans.id,
                        estado_id: this.datosTrans.estado_id
                    }
                }).then(res=>{            				        			    		
                    //this.listaEstab = res.data._embedded.establecimiento;
                    //console.log(res.data.detail[0])
                    //this.datosTrans = res.data.detail[0]
                    this.dialogEstadoTrans=false;
                    this.cargarLista()
                }).then(()=>{
                      
                });
            }
        },

        mounted() {
             //this.cargarEstablecimientos()
             this.cargarLista()
             this.setTitleToolbar('TRANSFERENCIAS BANCARIAS')
        },

        watch: {            

        }            
    }

    </script>